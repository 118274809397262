<!-- app-Header -->
<div class="app-header header sticky">
  <div class="container-fluid main-container">
    <div class="d-flex">
      <a
        aria-label="Hide Sidebar"
        class="app-sidebar__toggle"
        data-bs-toggle="sidebar"
        href="#"
      ></a>
      <!-- sidebar-toggle-->
      <a class="logo-horizontal me-md-3" routerLink="/summary"
        (click)="trackNavigation('Dashboard')">
        <img
          src="../../assets/images/brand/logo.png"
          class="header-brand-img desktop-logo"
          alt="logo"
        />
        <img
          src="../../assets/images/brand/logo-3.png"
          class="header-brand-img light-logo1"
          alt="logo"
        />
      </a>
      <!-- LOGO -->
      <div class="d-none d-md-block">
        <div class="dropdown d-md-flex profile-1 text-nowrap">
          <a
            href="#"
            data-bs-toggle="dropdown"
            class="nav-link leading-none d-flex animate fs-14 py-0 mt-2 pe-0 border-start ms-2 ps-4"
          >
            <div class="p-1 d-flex d-lg-none-max">
              <div>
                <h6 class="mb-0">{{ selectedNetwork.name }}</h6>
                <div class="fs-12 opacity-75 pt-1 text-muted">
                  Network Id: {{ selectedNetwork.networkId }}
                </div>
              </div>
              <i
                *ngIf="networkList.length > 1"
                class="user-angle ms-1 fa fa-angle-down"
              ></i>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-arrow mt-1" style="max-height: 80vh; overflow-y: auto;"
            *ngIf="networkList.length > 1"
          >
            <ng-container *ngFor="let network of networkList">
              <a
                class="dropdown-item border-top"
                *ngIf="selectedNetwork.networkId !== network.networkId"
                href="javascript: void(0)"
                (click)="selectNetworkHandlar(network)"
              >
                {{ network.name }}
                <span class="d-block opacity-75">Network Id: {{ network.networkId }}</span>
              </a>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="d-none d-md-block">
        <div class="dropdown d-md-flex profile-1 text-nowrap">
            <a
            href="#"
            data-bs-toggle="dropdown"
            class="nav-link leading-none d-flex animate fs-14 py-0 mt-2 pe-0 border-start ms-2 ps-4"
            [attr.disabled]="practiceList.length === 1 ? true : null"
            >
            <div class="p-1 d-flex d-lg-none-max">
              <div>
                <h6 class="mb-0">Select Practices</h6>
                <div class="fs-12 opacity-75 pt-1 text-muted d-flex align-items-center">
                  {{selectedPracticeList[0].name}}
                  <div *ngIf="selectedPracticeList.length>1">
                      (+{{selectedPracticeList.length - 1}})
                  </div>
                </div>
              </div>
              <i
                *ngIf="practiceList.length > 1"
                class="user-angle ms-1 fa fa-angle-down"
              ></i>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-arrow mt-1" style="max-height: 80vh; overflow-y: auto;"
            *ngIf="practiceList.length > 1"
          >
            <ng-container *ngFor="let practice of practiceList">
              <div class="dropdown-item border-top">
                <label class="d-flex align-items-center">
                  <input
                    type="checkbox"
                    [checked]="isPracticeSelected(practice)"
                    (change)="togglePracticeSelection(practice)"
                    class="mr-2"
                  >
                  <span>
                    {{ practice.name }}
                    <span class="d-block opacity-75">NPI: {{ practice.npi }}</span>
                  </span>
                </label>
              </div>
            </ng-container>
            <div class="dropdown-item border-top">
              <button
                class="btn btn-primary btn-sm w-100"
                (click)="applySelectedPractices()"
                [disabled]="selectedPracticeList.length === 0"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="main-header-center ms-5 me-3 d-none d-xl-block">
        <app-search></app-search>
      </div>
      <div class="d-flex order-lg-2 ms-auto header-right-icons">
        <div class="dropdown d-xl-none d-md-block d-none">
          <a href="#" class="nav-link icon" data-bs-toggle="dropdown">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              viewBox="0 0 24 24"
            >
              <path
                fill=""
                d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z"
              />
            </svg>
          </a>
          <div
            class="dropdown-menu header-search dropdown-menu-start search-tablet"
          >
            <app-search></app-search>
          </div>
        </div>
        <!-- SEARCH -->
        <button
          class="navbar-toggler navresponsive-toggler d-md-none ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent-4"
          aria-controls="navbarSupportedContent-4"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon fe fe-more-vertical"></span>
        </button>
        <div class="navbar navbar-collapse responsive-navbar p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
            <div class="d-flex order-lg-2 justify-content-center">
              <div class="dropdown d-md-none d-flex">
                <a href="#" class="nav-link icon" data-bs-toggle="dropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z"
                    />
                  </svg>
                </a>
                <div class="dropdown-menu header-search dropdown-menu-start">
                  <div class="main-header-center p-1">
                    <app-search></app-search>
                  </div>
                </div>
              </div>
              <!-- SEARCH -->
              <div class="d-none d-lg-flex">
                <a routerLink="/summary" summary class="nav-link icon"
                  (click)="trackNavigation('Dashboard')">
                  <div class="text-center py-1 d-flex d-lg-none-max">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 24 24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z"
                      ></path>
                    </svg>
                  </div>
                </a>
                <a
                  routerLink="/patients"
                  (click)="trackNavigation('Patients')"
                  class="nav-link pe-2"
                  [ngClass]="{
                    'is-active': this.currentRoute.indexOf('patients') !== -1
                  }"
                >
                  <div class="text-center py-1 d-flex d-lg-none-max">
                    Patients
                  </div>
                </a>
                <a
                  routerLink="/admission"
                  (click)="trackNavigation('Utilization')"
                  class="nav-link pe-2"
                  [ngClass]="{
                    'is-active': this.currentRoute.indexOf('admission') !== -1
                  }"
                >
                  <div class="text-center py-1 d-flex d-lg-none-max">
                    Utilization
                  </div>
                </a>
                <a
                  routerLink="/account"
                  (click)="trackNavigation('Account')"
                  class="nav-link pe-2"
                  [ngClass]="{
                    'is-active': this.currentRoute.indexOf('account') !== -1
                  }"
                >
                  <div class="text-center py-1 d-flex d-lg-none-max">
                    Account
                  </div>
                </a>
                <a
                  routerLink="/documents/recentdocuments"
                  (click)="trackNavigation('Documents')"
                  class="nav-link pe-2"
                  [ngClass]="{
                    'is-active': this.currentRoute.indexOf('documents') !== -1
                  }"
                >
                  <div class="text-center py-1 d-flex d-lg-none-max">
                    Documents
                  </div>
                </a>
                <div class="dropdown d-md-flex profile-1 text-nowrap pe-2">
                  <a
                    href="#"
                    data-bs-toggle="dropdown"
                    class="nav-link pe-0 leading-none d-flex animate"
                    [ngClass]="{
                      'is-active': this.currentRoute.indexOf('reports') !== -1
                    }"
                  >
                    Reports <i class="user-angle ms-1 fa fa-angle-down"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                  >
                    <!-- <a
                      class="dropdown-item border-top"
                      routerLink="/reports/insight"
                    >
                      Patient Insight
                    </a> -->
                    <a
                      class="dropdown-item"
                      routerLink="/reports/qualityMetrics"
                      (click)="trackNavigation('Clinical Quality Metrics')"
                    >
                      Clinical Quality Metrics
                    </a>
                    <a
                      class="dropdown-item border-top"
                      routerLink="/reports/riskAnalysis"
                      (click)="trackNavigation('Patient Risk Stratification')"
                    >
                      Patient Risk Stratification
                    </a>
                    <a
                      class="dropdown-item border-top"
                      routerLink="/reports/providerMetrics"
                      (click)="trackNavigation('Provider Level Metrics')"
                    >
                      Provider Level Metrics
                    </a>
                    <a *ngIf="selectedPractice.programType === programTypes.CKCC"
                      class="dropdown-item border-top"
                      routerLink="reports/ckcc-census"
                      (click)="trackNavigation('CKCC Daily Census')"
                    >
                     CKCC Daily Census
                    </a>
                    <a *ngIf="selectedPractice.programType === programTypes.CKCC"
                      class="dropdown-item border-top"
                      routerLink="reports/ckcc-ops"
                      (click)="trackNavigation('CKCC Daily Ops Metrics')"
                    >
                     CKCC Daily Ops Metrics
                    </a>

                    <a *ngIf="selectedPractice.programType === programTypes.CKCC"
                      class="dropdown-item border-top"
                      routerLink="/reports/ckcc"
                      (click)="trackNavigation('CKCC Monthly Summary')"
                    >
                      CKCC Monthly Summary
                    </a>
                    <a 
                      class="dropdown-item border-top"
                      routerLink="/reports/metricComparison"
                      (click)="trackNavigation('Metric Performance Comparison')"
                    >
                      Metric Performance Comparison
                    </a>
                  </div>
                </div>
                <div class="dropdown d-md-flex profile-1 text-nowrap pe-2" *ngIf="userInfo.role === userRoleTypes.SYTEM_ADMIN">
                  <a
                    href="#"
                    data-bs-toggle="dropdown"
                    class="nav-link pe-0 leading-none d-flex animate"
                    [ngClass]="{
                      'is-active': this.currentRoute.indexOf('users-management') !== -1
                    }"
                  >
                    Admin <i class="user-angle ms-1 fa fa-angle-down"></i>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                  >
                    <a
                      class="dropdown-item"
                      routerLink="/users-management/usersList"
                      (click)="trackNavigation('User List')"
                    >
                      User List
                    </a>
                    <a
                      class="dropdown-item"
                      routerLink="/network-management/networks-list"
                      (click)="trackNavigation('Network Management')"
                    >
                      Network Management
                    </a>
                  </div>
                </div>
              </div>
              <!-- NAVIGATION -->

              <app-notifications></app-notifications>
              <!-- NOTIFICATIONS -->
              <div class="dropdown d-md-flex profile-1 text-nowrap">
                <a
                  href="#"
                  data-bs-toggle="dropdown"
                  class="nav-link pe-0 leading-none d-flex animate"
                >
                  <span class="avatar__wrap">
                    {{ userInfo.fullName | initials }}
                    <!-- <img
                      src="../../assets/images/faces/6.jpg"
                      alt="profile-user"
                      class="avatar profile-user brround cover-image"
                    /> -->
                  </span>
                  <div class="p-1 d-flex d-lg-none-max">
                    <div>
                      <h6 class="mb-0" id="profile-heading">
                        {{ userInfo.fullName }}
                      </h6>
                      <div class="fs-12 opacity-75">
                        {{ userInfo.roleName }}
                      </div>
                    </div>
                    <i class="user-angle ms-1 fa fa-angle-down"></i>
                  </div>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                >
                  <!--<a class="dropdown-item" routerLink="/patient-profile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-inner-icn"
                      enable-background="new 0 0 24 24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M14.6650391,13.3672485C16.6381226,12.3842773,17.9974365,10.3535767,18,8c0-3.3137207-2.6862793-6-6-6S6,4.6862793,6,8c0,2.3545532,1.3595581,4.3865967,3.3334961,5.3690186c-3.6583862,1.0119019-6.5859375,4.0562134-7.2387695,8.0479736c-0.0002441,0.0013428-0.0004272,0.0026855-0.0006714,0.0040283c-0.0447388,0.272583,0.1399536,0.5297852,0.4125366,0.5745239c0.272522,0.0446777,0.5297241-0.1400146,0.5744629-0.4125366c0.624939-3.8344727,3.6308594-6.8403931,7.465332-7.465332c4.9257812-0.8027954,9.5697632,2.5395508,10.3725586,7.465332C20.9594727,21.8233643,21.1673584,21.9995117,21.4111328,22c0.0281372,0.0001831,0.0562134-0.0021362,0.0839844-0.0068359h0.0001831c0.2723389-0.0458984,0.4558716-0.303833,0.4099731-0.5761719C21.2677002,17.5184937,18.411377,14.3986206,14.6650391,13.3672485z M12,13c-2.7614136,0-5-2.2385864-5-5s2.2385864-5,5-5c2.7600708,0.0032349,4.9967651,2.2399292,5,5C17,10.7614136,14.7614136,13,12,13z"
                      ></path>
                    </svg>
                    Profile
                  </a>-->
                  <a
                    class="dropdown-item layout-setting border-top"
                    href="javascript:void(0);"
                  >
                    <span class="dark-layout fs-12">
                      <i class="fe fe-star me-2"></i>
                      Dark Theme
                    </span>
                    <span class="light-layout fs-12">
                      <i class="fe fe-sun me-2"></i>
                      Light Theme
                    </span>
                  </a>
                  <a
                    class="dropdown-item full-screen-link fs-12 border-top"
                    href="javascript:void(0);"
                  >
                    <i class="fa fa-expand me-2"></i>
                    Full Screen
                  </a>
                  <a
                    class="dropdown-item border-top"
                    href="javascript:void(0);"
                    data-bs-target="#notificationModal"
                    data-bs-toggle="modal"
                  >
                  <span class="fs-12">
                    <i class="fa fa-bell me-2"></i>
                    Notifications
                  </span>                    
                  </a>
                  <a
                    class="dropdown-item border-top"
                    href="javascript: void(0);"
                    (click)="logOut()"
                  >
                    <i class="fa fa-sign-out me-2"></i> Log out
                  </a>
                </div>
              </div>
              <!-- PROFILE -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /app-Header -->
<!--APP-SIDEBAR-->
<div class="is-expanded d-lg-none">
  <div class="app-sidebar__overlay active" data-bs-toggle="sidebar"></div>
  <div class="app-sidebar open ps ps--active-y">
    <div class="side-header">
      <a class="header-brand1" href="index.html">
        <img
          src="../../assets/images/brand/logo.png"
          class="header-brand-img desktop-logo"
          alt="logo"
        />
        <img
          src="../../assets/images/brand/logo-1.png"
          class="header-brand-img toggle-logo"
          alt="logo"
        />
        <img
          src="../../assets/images/brand/logo-2.png"
          class="header-brand-img light-logo"
          alt="logo"
        />
        <img
          src="../../assets/images/brand/logo-3.png"
          class="header-brand-img light-logo1"
          alt="logo"
        /> </a
      ><!-- LOGO -->
    </div>
    <div class="main-sidemenu is-expanded">
      <ul class="side-menu open">
        <li class="slide">
          <a class="side-menu__item has-link" data-bs-toggle="slide" href="#">
            <div class="d-flex w-100 align-items-center">
              <div class="flex-fill">
                <h6 class="mb-0">{{ selectedPractice.name }}</h6>
                <div class="fs-12 opacity-75 pt-1 text-muted">
                  {{ selectedPractice.npi }}
                </div>
              </div>
              <i class="angle fa fa-angle-right"></i>
            </div>
          </a>
          <ul class="slide-menu ms-0">
            <li class="border-top" *ngIf="networkList.length > 1">
              <ng-container *ngFor="let network of networkList">
                <a
                  class="slide-item d-block"
                  *ngIf="selectedNetwork.networkId !== network.networkId"
                  href="javascript: void(0)"
                  (click)="selectNetworkHandlar(network)"
                >
                  <div>{{ network.name }}</div>
                  <div class="d-block opacity-75">{{ network.networkId }}</div>
                </a>
              </ng-container>
            </li>
          </ul>
        </li>
        <li class="slide border-top">
          <a class="side-menu__item has-link" routerLink="/summary"
            (click)="trackNavigation('Dashboard')"
            >
            <span class="side-menu__label">Dashboard</span>
          </a>
        </li>
        <li class="slide border-top">
          <a class="side-menu__item has-link" routerLink="/patients"
            (click)="trackNavigation('Patients')"
            >
            <span class="side-menu__label">Patients</span>
          </a>
        </li>
        <li class="slide border-top">
          <a class="side-menu__item has-link" routerLink="/admission"
          (click)="trackNavigation('Utilization')"
          >
            <span class="side-menu__label">Admissions</span>
          </a>
        </li>
        <li class="slide border-top">
          <a class="side-menu__item has-link" routerLink="/documents"
          (click)="trackNavigation('Documents')"
          >
            <span class="side-menu__label">Documents</span>
          </a>
        </li>
        <li class="slide border-top">
          <a class="side-menu__item has-link" data-bs-toggle="slide" href="#">
            <span class="side-menu__label">Reports</span>
            <i class="angle fa fa-angle-right"></i>
          </a>
          <ul class="slide-menu ms-0">
            <li class="border-top">
              <a routerLink="/reports/qualityMetrics" class="slide-item"
              (click)="trackNavigation('Clinical Quality Metrics')"
                >Clinical Quality Metrics</a
              >
            </li>
            <li class="border-top">
              <a routerLink="/reports/riskAnalysis" class="slide-item"
              (click)="trackNavigation('Patient Risk Stratification')"
                >Patient Risk Stratification</a
              >
            </li>
          </ul>
        </li>
        <li class="slide border-top" *ngIf="userInfo.role === userRoleTypes.SYTEM_ADMIN">
          <a class="side-menu__item has-link" data-bs-toggle="slide" href="#">
            <span class="side-menu__label">Admin</span>
            <i class="angle fa fa-angle-right"></i>
          </a>
          <ul class="slide-menu ms-0">
            <li class="border-top">
              <a routerLink="/users-management/usersList" class="slide-item"
              (click)="trackNavigation('User List')"
                >User List</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="header-spacer"></div>
<!--/APP-SIDEBAR-->


<div class="modal fade" id="notificationModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-demo">
      <form>
        <div class="modal-header bg-gray-200">
            <h4 class="modal-title fw-semibold">Notifications</h4>
            <button type="button" aria-label="Close" data-bs-dismiss="modal" class="btn-close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
          <app-spinner *ngIf="showLoading"></app-spinner>
          <h5><b>Two Factor Authentication</b> one time password to be sent via</h5>
          <hr class="mb-1" />
          <form [formGroup]="OTPSettingForm">
            <div class="d-flex border-bottom py-3 align-items-center">
              <div class="flex-fill">
                <h5 class="form-check-label">Email</h5>
                <small class="text-muted"><i>Sent on your primary email</i></small>
              </div>
              <div>
                <div class="form-check form-switch">
                  <input formControlName="isEmailEnabled" class="form-check-input" type="checkbox" [checked]="OTPSettingForm.controls['isEmailEnabled'].value"
                    (change)="updateNotificationMedium('email')">
                </div>
              </div>
            </div>
            <div class="d-flex py-3 align-items-center">
              <div class="flex-fill">
                <h5 class="form-check-label">Text Message</h5>
                <small class="text-muted"><i>Sent to your mobile number</i></small>
              </div>
              <div>
                <div class="form-check form-switch">
                  <input formControlName="isSmsEnabled" class="form-check-input" type="checkbox" [checked]="OTPSettingForm.controls['isSmsEnabled'].value"
                    (change)="updateNotificationMedium('text')">
                </div>
              </div>
            </div>
          </form>
        </div>
      </form>
    </div>
  </div>
</div>