import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { PatientService, PaymentReportService } from "src/app/api-client";
import { ICustomLogEvents, ISessionDetails} from "src/app/interfaces/customLogEvents";
import { GridModel } from "src/app/interfaces/grid.model";
import { LoggingService } from "src/app/services/logging.service";
import { environment } from "src/environments/environment";
import { SharedModule } from "../../../components/shared.module";
import { NgClass, NgIf, NgFor } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentPatientReportModel } from "src/app/api-client/model/paymentPatientReportModel";
import { EventService } from "src/app/services/event.service";
import { AccountPatientFilter } from "src/app/interfaces/accout-patient-filter";
declare let $: any;

@Component({
  selector: "app-accout-patient-list",
  standalone: true,
  imports: [SharedModule, NgClass, NgIf, NgFor],
  templateUrl: "./account-patient-list.component.html",
  styleUrl: "./account-patient-list.component.scss",
})
export class AccountPatientListComponent implements OnInit {
  monthYear: string="";
  disabledExport = false;
  @Output() actionHandler: EventEmitter<any> = new EventEmitter();
  iterationCount: number = 10;
  selectedPractice: any;
  userInfo: any;
  loadingPymentReport: boolean = false;
  exportStatus = '';
  pateintReportModel: PaymentPatientReportModel = {} as PaymentPatientReportModel;
  fileNameExport = '';
  gridData: AccountPatientFilter = {
    monthYear: "",
    SortBy: 'patientname',
    SortDirection: 'asc',
    pagingModel: {
      pageSize: environment.pageSize,
      totalRecords: 0,
      currentPage: 1,
      totalPages: 0,
    },
  };
  list: any = [];
  showLoading: boolean = false;

  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = "Account Pateint - Web";

  constructor(
    private paymentReportService: PaymentReportService,
    private loggingService: LoggingService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private patientService: PatientService,
    private router: Router
  ) {
    const monthParam = this.route.snapshot.params['month'];
    const [month, year] = monthParam.split('-');
    this.gridData.monthYear = `${month.charAt(0).toUpperCase()}${month.slice(1).toLowerCase()} ${year}`;
  }
  ngOnInit(): void {
    this.loggingService.eventService
      .selectedPracticeSubscription()
      .subscribe((practice) => {
        this.selectedPractice = practice;
      });
    this.loggingService.storeService
      .userInfoSubscription()
      .subscribe((info) => {
        this.userInfo = info;
      });
    this.sessionDetails = this.loggingService.getSessionDetails(
      this.userInfo,
      this.selectedPractice
    );
    this.loggingService.logEventForComponentRendering(
      this.customEvent,
      this.sessionDetails
    );
    this.loadPatient(this.gridData);
  }
  loadPatient(gridData: AccountPatientFilter) {
    this.showLoading = true;
    this.customLog = this.loggingService.createCustomLog("Paient in " + gridData.monthYear,"","INFO",this.sessionDetails,"/api/PaymentReport/GetmontlyPatient","GET",{});
    this.paymentReportService.apiPaymentReportGetMontlyPatientMonthYearGet(gridData).subscribe({
        next: (response: any) => {
          if (response.data) {
            this.list = response.data;
            this.gridData.pagingModel = response.pagingModel;
            this.showLoading = false;
            this.customLog.message = "API Call Successful";
            this.loggingService.trackEvent(this.customEvent, this.customLog);
          }
        },
        error: (error) => {
          this.showLoading = false;
          this.customLog.level = 'ERROR';
          this.customLog.message = error;
          this.loggingService.trackEvent(this.customEvent, this.customLog);
          this.loggingService.logException(error);
        },
      });
  }

   
  submitExport(): void {
     this.disabledExport =true;
    this.pateintReportModel.fileName = this.fileNameExport;
    this.pateintReportModel.monthYear = this.monthYear;
    this.disabledExport = true;
    this.exportStatus = 'inprogress';
    this.customLog = this.loggingService.createCustomLog("Payment Patient Export ","","INFO",this.sessionDetails,"/api/PaymentReport/PaymentPatientExport","POST",{});
    
    this.paymentReportService.apiPaymentReportPaymentPatientExportPost(this.pateintReportModel).subscribe({
      next: () => {
        this.fileNameExport = '';
        $('#providerexportModel').modal('hide');
        this.disabledExport = false;
        this.eventService.openToaster({
          showToster: true,
          message: `Account Report - Export request submitted successfully.`,
          type: 'success'
        });
        this.eventService.notificationEventUpdate(true);
        this.customLog.message = "API Call Successful";
        this.loggingService.trackEvent(this.customEvent, this.customLog);
      },
      error: (ex: any) => {
        this.exportStatus = 'error';
        this.disabledExport = false;
        this.fileNameExport = '';
        this.handleApiError(ex);
      }
    });
  }
  
  private handleApiError(error: any): void {
    this.customLog.level = 'ERROR';
    this.customLog.message = error;
    this.loggingService.trackEvent('Provider Level Report - Web', this.customLog);
    this.loggingService.logException(error);
    this.showLoading = false;
  }

  acceptEnter(event: any): void {
    if (event.code === 'Enter' || (event.keyCode === 13 && event.location === 3)) {
      this.submitExport();
    }
  }
  
  getFormatDate(dob: Date) {
    const date = moment(dob);
    return date.isValid() ? date.format("MM/DD/YYYY") : "";
  }
  getAge(dob: any) {
    return moment().diff(dob, "years");
  }
  getIterations(): number[] {
    return new Array(this.iterationCount);
  }
  renderArrowIcon(columnName: string) {
    if(columnName === this.gridData.SortBy && this.gridData.SortDirection === 'asc') {
      return 'table-header-asc';
    } else if(columnName === this.gridData.SortBy) {
      return 'table-header-desc';
    }
    return '';
  }
  applySort(columnName: string) {
    const prevSortBy = this.gridData.SortBy;
    if(prevSortBy === columnName && this.gridData.SortDirection === '') {
      this.gridData.SortDirection = 'asc';
    } else {
      this.gridData.SortDirection = '';
    }
    this.gridData.SortBy = columnName;
    this.loadPatient(this.gridData);
  }
  handleKeyDown(event: any): void {
 console.log(event);
  }
  public gotoPage(page: number): void {
    this.gridData.pagingModel.currentPage = page;
    this.loadPatient(this.gridData);
  }
}
