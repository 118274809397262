import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService, NetworkService, NotificationService, PracticeService, UserRoleService } from '../../api-client';
import { EventService } from 'src/app/services/event.service';
import { Messages } from 'src/app/shared/common-constants/messages';
import { AuthService } from 'src/app/services/auth.service';
import { UserInfo } from 'src/app/interfaces/user';
import { StoreService } from 'src/app/services/store.service';
import { filter } from 'rxjs/operators';
import { Roles } from 'src/app/enums/roles';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TwoFactorOTPMedium } from 'src/app/enums/twoFactorOTPMedium';
import { ProgramTypes } from 'src/app/enums/programTypes';
import { LoggingService } from 'src/app/services/logging.service';
import { ICustomLogEvents, ISessionDetails } from 'src/app/interfaces/customLogEvents';

type Practice = {
  isSelected: boolean;
  name: string;
  practiceId: number;
  npi: string;
  programType: string;
}
type Network ={
  isSelected: boolean;
  name: string;
  networkId: number;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  userRoleTypes = Roles;
  programTypes = ProgramTypes;
  messages: any = Messages;
  selectedPractice: Practice = {} as Practice;
  selectedPracticeList: Practice[] =[];
  networkList : Network[] =[];
  selectedNetwork: Network = {} as Network;
  OTPSettingForm: FormGroup = this.fb.group({
    isEmailEnabled: [],
    isSmsEnabled: []
  });
  showLoading: boolean = false;
  practiceList: Practice[] = [];
  userInfo: UserInfo = {
    fullName: '',
    roleName: '',
    role: Roles.PRACTICE_USER,
    twoFactorNotificationMedium: 0
  };
  customLog!: ICustomLogEvents;
  sessionDetails!: ISessionDetails;
  customEvent: string = 'Navigations - Web';
  currentRoute: string = '/';
  rolesLoaded: boolean = false;
  constructor(
    private _accountService: AccountService,
    private practiceService: PracticeService,
    private route: Router,
    private eventService: EventService,
    private authService: AuthService,
    private storeService: StoreService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private userRolesService: UserRoleService,
    private readonly loggingService: LoggingService,
    private readonly networkService: NetworkService
  ) {
    route.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentRoute = event.url;
    });
  }
  async ngOnInit() {
    this.eventService.userLoggedInSubscription().subscribe({
      next: (response: boolean) => {
        if (!this.authService.isLoggedIn()) return;
        this.loadNetworkList();
        this.loadPracticeList();
        this.loadUserRoles();
        this.setNotificationFormValues();
      }
    })
    this.storeService.userInfoSubscription().subscribe(async (info: UserInfo) => {
      this.userInfo = info;
      this.setNotificationFormValues();
      if (!this.authService.isLoggedIn()) return;
      if(!this.rolesLoaded) {
        this.loadUserRoles();
      }
      if(this.userInfo && this.userInfo.fullName) {
        return;
      }
      try {
        this.customLog = this.loggingService.createCustomLog('API to Get User Information', '', 'INFO', this.sessionDetails, `/api/Account/user-info`, 'GET', {});
        const info = await this._accountService.apiAccountUserInfoGet().toPromise();
        this.eventService.startTimer();
        this.storeService.userInfo({
          ...this.userInfo,
          ...info as UserInfo
        });
        this.customLog.message = 'User Information Fetched Successfully';
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        if(!this.rolesLoaded) {
          this.loadUserRoles();
        }
      } catch(error: any) {
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
      }
    })
  }
  setNotificationFormValues() {
    if(this.userInfo.twoFactorNotificationMedium === TwoFactorOTPMedium.ALL) {
      this.OTPSettingForm.patchValue({
        isEmailEnabled: true,
        isSmsEnabled: true
      });
    } else if(this.userInfo.twoFactorNotificationMedium === TwoFactorOTPMedium.EMAIL) {
      this.OTPSettingForm.patchValue({
        isEmailEnabled: true,
        isSmsEnabled: false
      });
    } else if(this.userInfo.twoFactorNotificationMedium === TwoFactorOTPMedium.SMS) {
      this.OTPSettingForm.patchValue({
        isEmailEnabled: false,
        isSmsEnabled: true
      });
    }
  }
  updateNotificationMedium(type: string){
    this.showLoading = true;
    if(!this.OTPSettingForm.value.isEmailEnabled && !this.OTPSettingForm.value.isSmsEnabled) {
      if(type === 'email') {
        this.OTPSettingForm.patchValue({
          isSmsEnabled: true
        });
      } else if(type === 'text') {
        this.OTPSettingForm.patchValue({
          isEmailEnabled: true,
        });
      }
    }
    this.customLog = this.loggingService.createCustomLog('Update Notification Medium', '', 'INFO', this.sessionDetails, `/api/Notification/update/notify-medium`, 'PUT', this.OTPSettingForm.value);
    this.notificationService.apiNotificationUpdateNotifyMediumPut(this.OTPSettingForm.value).subscribe({
      next: (status: any) => {
        this.showLoading = false;
        this.customLog.message = 'Notification Medium Updated Successfully';
        this.loggingService.trackEvent(this.customEvent, this.customLog);
      },
      error: (error) => {
        this.showLoading = false;
        this.eventService.openToaster({
          showToster: true,
          message: `Error While setting notifications alert.`,
          type: 'danger',
        });
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
      }
    });
  }
  private loadUserRoles() {
    if (!this.userInfo.userLoginId || !this.userInfo.userLoginId) return;
    const id = this.userInfo.userLoginId;
    this.customLog = this.loggingService.createCustomLog('Get Current User Roles', '', 'INFO', this.sessionDetails, `/api/user-roles/${id}`, 'GET', {});
    this.userRolesService.apiUserRolesUserLoginIdGet(id).subscribe({
      next: (response: any) => {
        this.rolesLoaded = true;
        this.storeService.userInfo({
          ...this.userInfo,
          role: response[0].roleId
        });
        this.customLog.message = 'User Roles Fetched Successfully';
        this.loggingService.trackEvent(this.customEvent, this.customLog);
      },
      error: (error: any) => {
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
      }
    })
  }
  private loadNetworkList(){
    this.customLog = this.loggingService.createCustomLog('Load Network List', '', 'INFO', this.sessionDetails, `/api/Network/NetworkAssignToUser`, 'GET', {});
    this.networkService.apiNetworkNetworkAssignToUserGet().subscribe({
      next: (response: any) => {
        this.networkList = response;
        if (!this.networkList.length) {
          this.eventService.errorMessageUpdate({
            type: 'error',
            title: '',
            body: this.messages.errorPractice
          });
          this.route.navigate(['/error']);
          return;
        };
        this.customLog.message = 'Network List Loaded Successfully';
        const selectedItem = response.filter((item: Network) => item.isSelected);
        if (selectedItem.length > 0) {
          this.selectedNetwork = selectedItem[0];
        } else {
          this.selectedNetwork = this.networkList[0];
          this.selectNetworkHandlar(this.selectedNetwork);
        }
        this.loggingService.trackEvent(this.customEvent, this.customLog);
      },
      error: (error: any) => {
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
      }
    })
  }
  private loadPracticeList() {
    this.customLog = this.loggingService.createCustomLog('Load Practice List', '', 'INFO', this.sessionDetails, `/api/Practice/list`, 'GET', {});
    this.practiceService.apiPracticeListGet().subscribe({
      next: (practiceList: any) => {
        this.practiceList = practiceList;
        if (!practiceList.length) {
          this.eventService.errorMessageUpdate({
            type: 'error',
            title: '',
            body: this.messages.errorPractice
          });
          this.route.navigate(['/error']);
          return;
        };
        const selectedItem = practiceList.filter((item: Practice) => item.isSelected);
        if (selectedItem.length > 0) {
          this.selectedPracticeList = selectedItem;
        } else {
          this.selectedPractice = this.practiceList[0];
          this.selectPracticeHandlar([this.selectedPractice]);
        }
        this.eventService.selectedPractice(this.selectedPractice);
        this.customLog.message = 'Practice List Loaded Successfully';
        this.loggingService.trackEvent(this.customEvent, this.customLog);
      },
      error: (error) => {
        this.eventService.errorMessageUpdate({
          type: 'error',
          title: '',
          body: this.messages.errorPractice
        });
        this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
        this.route.navigate(['/error']);
      },
      
    })
  }
  public logOut() {
   this.authService.logOut(); 
  }
  selectPracticeHandlar(practice: Practice[]) {
    const practiceIdList = practice.map(practiceItem => practiceItem.practiceId);
    this.customLog = this.loggingService.createCustomLog(`Practice Changed to ${practice[0].name}`, '', 'INFO', this.sessionDetails, `/api/Practice/update/${this.selectedPractice.practiceId}`, 'GET', {});
    this.practiceService.apiPracticeUpdateUserPracticePost(practiceIdList)
    .subscribe((response: any) => {
      if (window.location.href.includes('patient-profile')) {
        this.route.navigate(['/patients']);
        this.loadPracticeList();
      }
      // else {
      //   if (practice.name === "Renal Medical Associates") {
      //     this.route.navigate(['/reports/ckcc']);
      //   }else{
      //     this.route.navigate(['/summary']);
      //   }
      // }
      else{
        setTimeout(() => {
          window.location.reload();
        }, 1200);
      }
      this.customLog.message = 'Practice Changed Successfully';
      this.loggingService.trackEvent(this.customEvent, this.customLog);
    },
  (error: any) => {
    this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
    });
  }

  selectNetworkHandlar(network: Network) {
    this.selectedNetwork = network;
    this.customLog = this.loggingService.createCustomLog(`Network Changed to ${network.name}`, '', 'INFO', this.sessionDetails, `/api/Network/update/${this.selectedNetwork.networkId}`, 'GET', {});
    this.networkService.apiNetworkUpdateNetworkIdPut(this.selectedNetwork.networkId)
    .subscribe((response: any) => {
      
      if (window.location.href.includes('patient-profile')) {
        this.route.navigate(['/patients']);
        this.loadPracticeList();
      }
      else{
        setTimeout(() => {
          window.location.reload();
        }, 900);
      }
      this.customLog.message = 'Network Changed Successfully';
      this.loggingService.trackEvent(this.customEvent, this.customLog);
    },
  (error: any) => {
    this.customLog.level = 'ERROR';
        this.customLog.message = error;
        this.loggingService.trackEvent(this.customEvent, this.customLog);
        this.loggingService.logException(error);
    });
  }

  trackNavigation(navMenu: string) {
    this.sessionDetails = this.loggingService.getSessionDetails(this.userInfo, this.selectedPractice);
    this.customLog = this.loggingService.createCustomLog('Navigation Event', `${navMenu} Navigation Clicked`, 'INFO', this.sessionDetails);
    this.loggingService.trackEvent(this.customEvent, this.customLog);
  }
  isPracticeSelected(practice: Practice): boolean {
    return this.selectedPracticeList.some(p => p.practiceId === practice.practiceId);
  }

  togglePracticeSelection(practice: Practice): void {
    const index = this.selectedPracticeList.findIndex(p => p.practiceId === practice.practiceId);
    if (index > -1) {
      this.selectedPracticeList.splice(index, 1);
    } else {
      this.selectedPracticeList.push({ ...practice, isSelected: true });
    }
  }
  applySelectedPractices(): void {
    debugger;
    this.selectPracticeHandlar(this.selectedPracticeList);
  }
}

